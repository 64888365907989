$(function() {

    // Enable tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Carousel events
    $('#front-page-carousel').on('slide.bs.carousel', function () {
        $('.carousel-item-title').removeClass('is-visible');
    });
    $('#front-page-carousel').on('slid.bs.carousel', function () {
        $('.carousel-item-title').addClass('is-visible');
    });

});

$(window).on("load", function(){
    $(".loader-container").hide();

    $('.grid').masonry({
        columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer',
        itemSelector: '.grid-item',
        percentPosition: true
    });

    $('.grid-item-container').each(function(i){
        setTimeout(function(){
            $('.grid-item-container').eq(i).addClass('is-visible');
        }, 150 * i);
    });

    $('.appear-on-load, .appear-on-load-moved, .appear-on-load-slide').addClass('is-visible');
});